
export default {
  inheritAttrs: false,

  props: {
    activeSegments: {
      type: Number,
      required: true,
    },

    /*
    size: small, input
    */
    size: {
      type: String,
      required: true,
    },

    colorName: {
      type: String,
      required: true,
    },

    explanationText: {
      type: String,
      default: undefined,
    },
  },
};
