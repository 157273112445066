import { render, staticRenderFns } from "./TableRowTenanciesSavedFilter.vue?vue&type=template&id=3866c341"
import script from "./TableRowTenanciesSavedFilter.vue?vue&type=script&setup=true&lang=ts"
export * from "./TableRowTenanciesSavedFilter.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./TableRowTenanciesSavedFilter.vue?vue&type=style&index=0&id=3866c341&prod&module=true&lang=css"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Copy: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/Copy/Copy.vue').default,SimpleTableCell: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableCell.vue').default,TickBox: require('/app/node_modules/@estaid/ui/dist/runtime/atoms/TickBox/TickBox.vue').default,SimpleTableRow: require('/app/node_modules/@estaid/ui/dist/runtime/molecules/SimpleTable/SimpleTableRow.vue').default})
